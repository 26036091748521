import React from "react";
import {
    flag
} from './footer.module.scss'

const Footer = () => {

    return (
        <section className="noPaddingBottom">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="small-text text-center">
                            Ⓒ Steglich-P. & Peetz-Schou ApS - 2025
                        </p>
                        <div className={flag} />
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Footer